import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import ScrollOut from "scroll-out"

import ArticleHeaderGrid from "../../../components/athena/articleHeaderGrid"
import ContributorsGrid from "../../../components/athena/contributorsGrid"
import MoreArticlesGrid from "../../../components/athena/moreArticlesGrid"
import AthenaCta from "../../../components/athena/athenaCta"
import Layout, { athenaTheme } from "../../../components/layout"
import PreFooter from "../../../components/athena/preFooter"
import ScrollProgressBar from "../../../components/scrollProgressBar"
import GridCollagePhoto from "../../../components/athena/gridCollagePhoto"
import { getMaxSectionImageWidth } from "./cycle3-shared"

import styles from "./cooperative-aesthetics.module.scss"
import sharedStyles from "./cycle3-shared.module.scss"

let theme = {
  ...athenaTheme,
  background: styles.background,
  foreground: styles.foreground,
  headerPosition: styles.position,
}

const Article = ({ data }) => {
  const article = data.craft.article
  const seo = article.seomatic
  const athenaCta = article.athenaCta

  let maxSectionImageWidth = getMaxSectionImageWidth()

  // Images
  const {
    headerImage,
    section1Image,
    section2Image,
    section3Image,
    section4Image,
  } = data.craft

  // Initialize up ScrollOut library
  useEffect(() => {
    let so = ScrollOut({
      threshold: 0.1,
      cssProps: {
        viewportY: true,
      },
    })

    return so.teardown
  }, [])

  return (
    <Layout seo={seo} mode="athena" theme={theme} shyHeader={false}>
      <Helmet>
        <body className={`athena ${styles.body}`} />
      </Helmet>
      <article>
        <ScrollProgressBar />
        <div className={styles.article}>
          <div className={styles.articleBody}>
            <div className={styles.text}>
              <div className={`${styles.narrow} wrapper`}>
                <div
                  className={`${styles.headerContentBlock} columns is-tablet is-centered`}
                >
                  <ArticleHeaderGrid
                    issue={article.issue}
                    title={article.title}
                    subtitle={article.articleSubTitle}
                    headerImage={headerImage}
                  />
                </div>
                <div
                  className={`${styles.contentBlock} columns is-tablet is-centered`}
                >
                  <div className="column is-10-tablet">
                    <div className="mb-8 user-content-athena">
                      <p>
                        Cooperation is born out of desire. To amplify its
                        potential, we need to rethink its appeal. And that
                        starts with rethinking aesthetics. It&rsquo;s time to
                        move towards creative structures that promote
                        cooperation in a compelling way.
                      </p>
                      <p className="a-h-2 italic">
                        Aesthetics are the art of attraction. They&rsquo;re what
                        bring us together.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={styles.twoColumn}
              style={{ backgroundColor: "#331F0D" }}
            >
              <div>
                <div className={sharedStyles.stickyPhotoWrapper}>
                  <GridCollagePhoto
                    src={section1Image.url}
                    width={section1Image.width}
                    height={section1Image.height}
                    maxWidth={maxSectionImageWidth}
                    imgix={true}
                    cols={6}
                    rows={8}
                    altText={section1Image.altText}
                  />
                </div>
              </div>
              <div className={styles.rightColumn}>
                <h2
                  className={styles.contentHeader}
                  style={{ color: "#F0CCA8" }}
                >
                  Expand human potential
                </h2>
                <div className="user-content-athena">
                  <p>
                    Storytelling is fundamental to what it means to be human. We
                    capture attention and inspire action through the stories we
                    tell. Social media offers ever-expanding opportunity for
                    connection, but to harness its cooperative potential we need
                    to look outward. The same structures that position social
                    media platforms as mirrors can also be used as a windows to
                    our surroundings. For both individuals and brands, when we
                    shift from self (or product)-centered stories to
                    community-centered stories, we&rsquo;re able to create
                    narratives that are far more complex and compelling. When we
                    reach out and elevate those around us, we can create
                    something larger than ourselves.
                  </p>

                  <p>
                    Last summer, individuals and organizations throughout our
                    hometown of Minneapolis took to social media to form and
                    support mutual aid networks to get neighbors the supplies
                    they needed in the midst of an uprising that reverberated
                    around the world. Scrolling through feeds revealed a
                    dramatic shift as local residents and businesses with no
                    prior organizing experience dedicated social platforms to
                    amplifying work being done in the community.
                  </p>
                </div>
              </div>
            </div>
            <div
              className={styles.twoColumn}
              style={{ backgroundColor: "#173457" }}
            >
              <div>
                <div className={sharedStyles.stickyPhotoWrapper}>
                  <GridCollagePhoto
                    src={section2Image.url}
                    width={section2Image.width}
                    height={section2Image.height}
                    maxWidth={maxSectionImageWidth}
                    imgix={true}
                    cols={8}
                    rows={6}
                    altText={section2Image.altText}
                  />
                </div>
              </div>
              <div className={styles.rightColumn}>
                <h2
                  className={styles.contentHeader}
                  style={{ color: "#BEE1F7" }}
                >
                  Incentivize co-creation
                </h2>
                <div className="user-content-athena">
                  <p>
                    When systems are built for competition, we compete. To
                    empower cooperation, we need to reengineer for a more
                    interconnected, interdependent future. The Internet was
                    built on the principles and protocols of open, peer-to-peer
                    collaboration out of necessity. Creating an entirely new
                    environment required profound cooperation. Collective
                    creativity empowers the exchange of ideas in the pursuit of
                    something far beyond what is attainable independently. To
                    drive innovation at a societal-level, we need participatory
                    systems. Current modes of competitive ownership are outdated
                    and structured to serve profit over people.
                  </p>

                  <p>
                    One way to make collaboration more intuitive and unlock the
                    potential of simultaneous ownership is through UX and UI
                    design. For an example of cooperative UI in action, look to{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.fastcompany.com/90338788/dreams-is-the-most-important-new-game-in-a-decade"
                    >
                      Dreams
                    </a>
                    , a video game built entirely around co-creation. Dreams
                    flips the competitive narrative of traditional video games
                    on its head. Users are given access to an extensive suite of
                    creative tools to build games within the game that are then
                    shared with and remixed by other users, sparking limitless
                    experimentation and iteration. Dreams constantly expands as
                    new users create, blurring the lines of video game and
                    experimental media with co-creation superseding all else.
                  </p>
                </div>
              </div>
            </div>

            <div
              className={styles.twoColumn}
              style={{ backgroundColor: "#32381A" }}
            >
              <div>
                <div className={sharedStyles.stickyPhotoWrapper}>
                  <GridCollagePhoto
                    src={section3Image.url}
                    width={section3Image.width}
                    height={section3Image.height}
                    maxWidth={maxSectionImageWidth}
                    imgix={true}
                    cols={6}
                    rows={10}
                    altText={section3Image.altText}
                  />
                </div>
              </div>
              <div className={styles.rightColumn}>
                <h2
                  className={styles.contentHeader}
                  style={{ color: "#E5D6A1" }}
                >
                  Embrace vulnerability
                </h2>
                <div className="user-content-athena">
                  <p>
                    Gritting our teeth and suppressing emotions is a behavior
                    born out of competition. Thankfully, public perception is
                    shifting. Increasingly, vulnerability is viewed as a
                    strength rather than a weakness. Sharing emotions allows us
                    to empathize and connect with others on a deeper, more
                    meaningful level. This emotional connection is key to
                    cooperation. Moving away from the hardening of competition
                    and towards a mindset that embraces the emotional
                    complexities of the human experience allows us to find
                    commonalities and foster solidarity.
                  </p>

                  <p>
                    Throughout the pandemic, we&rsquo;ve experienced waves of
                    uncertainty, fear, and loss. Further exacerbating these
                    feelings is the physical and social isolation that has left
                    many to face these feelings alone. In response,
                    &ldquo;We&rsquo;re better together&rdquo; emerged as a
                    common refrain from brands to provide positivity during the
                    darkest days of the pandemic. It&rsquo;s a worthy statement
                    of cooperation, but oftentimes this messaging glazed over
                    the suffering that many were experiencing. Far less common,
                    but more impactful were brands that addressed the collective
                    trauma more directly. By shining a light on the struggle,
                    brands like Dove fostered a sense of collective solidarity
                    by speaking directly to what many were feeling.
                  </p>
                </div>
              </div>
            </div>

            <div
              className={styles.twoColumn}
              style={{ backgroundColor: "#521B10" }}
            >
              <div>
                <div className={sharedStyles.stickyPhotoWrapper}>
                  <GridCollagePhoto
                    src={section4Image.url}
                    width={section4Image.width}
                    height={section4Image.height}
                    maxWidth={maxSectionImageWidth}
                    imgix={true}
                    cols={6}
                    rows={8}
                    altText={section4Image.altText}
                  />
                </div>
              </div>
              <div className={styles.rightColumn}>
                <h2
                  className={styles.contentHeader}
                  style={{ color: "#E5E0DB" }}
                >
                  Amplify Joy
                </h2>
                <div className="user-content-athena">
                  <p>
                    Joy as a collective expression has been in short supply the
                    past year. On top of a flood of contrary emotions, the
                    isolation of pandemic has made it so that even when there
                    are moments of joy, we&rsquo;re seldom able to share them.
                    Collective emotional expression has been the subject of
                    sociological interest for over a century. The French
                    sociologist Émile Durkheim identified "collective
                    effervescence" as a critical element of social cohesion. The
                    term might be new to most, but the feeling is familiar.
                    Collective effervescence describes the unifying euphoria
                    that comes from collective ritual on a dance floor, in a
                    sports arena, or in a religious space. The collective
                    expression of emotion, and joy in particular, carries a
                    force of belonging that bonds individuals in spite of
                    differences. We become a part of something larger than
                    ourselves.
                  </p>

                  <p>
                    The absence of collective joy during pandemic has made it
                    clear how deeply humans rely on collective emotional
                    experiences. Even when in-person gatherings return, we can
                    draw inspiration from those who created digital spaces to
                    foster collective joy during lockdown. Some of the most
                    vibrant, cooperative spaces arose in the form of streaming
                    isolation dance parties. Party series like{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.papermag.com/club-quarantine-zoom-parties-2645599288.html?rebelltitem=24#rebelltitem24"
                    >
                      Club Quarantine
                    </a>{" "}
                    repurposed video conferencing to channel the electricity of
                    bodies on a crowded dance floor. Club Q provides a space for
                    empowerment and experimentation, guided by the queer
                    underground dance community&rsquo;s long-standing ethic of
                    respect and radical inclusivity. Visitors from around the
                    world interact with the streaming video format as a sort of
                    social borderlands between the physical world and digital
                    realm, where online personas and IRL identities blend
                    together. Ideas are exchanged and identities shift at the
                    speed of the internet, with the added real-time performative
                    element of video.
                  </p>
                  <hr />
                  <p>
                    Cooperation is the future. Now is the time to make it more
                    compelling.
                  </p>
                </div>
              </div>
            </div>

            <ContributorsGrid
              zeus={article.zeusContributors}
              other={article.otherContributorsGrid}
            />
            <MoreArticlesGrid id={article.id} issue={article.issue[0].id} />
            <AthenaCta data={athenaCta} />
          </div>
          {/* .article-body */}
        </div>
        {/* .article */}
      </article>
      <PreFooter />
    </Layout>
  )
}

export default Article

export const query = graphql`
  query($slug: String!) {
    craft {
      article: entry(slug: [$slug], section: "athenaArticles") {
        id
        title
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        ... on Craft_athenaArticles_athenaArticleCustom_Entry {
          articleSubTitle
          issue {
            id
            title
          }
          coverImages {
            ... on Craft_coverImages_BlockType {
              ratio_16x9 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
              ratio_4x3 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
            }
          }
          zeusContributors {
            id
            title
            uri
            ... on Craft_people_person_Entry {
              portrait {
                url
                width
                height
                focalPoint
                ... on Craft_people_Asset {
                  altText
                }
              }
            }
          }
          otherContributors {
            ... on Craft_otherContributors_TableRow {
              name
              website
            }
          }
          otherContributorsGrid {
            ... on Craft_otherContributorsGrid_BlockType {
              contributorName
              contributorWebsite
              contributorPhoto {
                url
                width
                height
                focalPoint
                ... on Craft_athenaContent_Asset {
                  altText
                }
              }
            }
          }
          athenaCta {
            ...AthenaCta
          }
        }
      }
      headerImage: asset(id: 24077) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      section1Image: asset(id: 24078) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      section2Image: asset(id: 24079) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      section3Image: asset(id: 24080) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      section4Image: asset(id: 24081) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
    }
  }
`
